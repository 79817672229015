import { render, staticRenderFns } from "./Warehouse.vue?vue&type=template&id=a3480584&scoped=true"
import script from "./Warehouse.vue?vue&type=script&lang=js"
export * from "./Warehouse.vue?vue&type=script&lang=js"
import style0 from "./Warehouse.vue?vue&type=style&index=0&id=a3480584&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3480584",
  null
  
)

export default component.exports